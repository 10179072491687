.boxsetResume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.boxsetResume > * {
  margin-right: 12px;
}
.boxsetResume .boxsetIcon {
  height: auto;
  width: 30px;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.boxsetResume strong {
  font-size: 22px;
  margin-bottom: 0;
}
.boxsetResume .salaBadge {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
}
.boxsetResume .expire {
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
  min-width: 100%;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
}
.boxsetResume .expire svg {
  margin-right: 0.5rem;
  width: 16px;
}
.boxsetResume .salaBadge.nights {
  background: transparent;
  color: #333333;
  padding: 0;
}